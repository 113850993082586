import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

// Reset browsers
const withStyled = css`
  border: none;
  margin: 0;
  width: 100%;
`;

const hrPropertyTypes = {
  styled: PropTypes.bool,
  height: PropTypes.string,
  backgroundColor: PropTypes.string,
  margin: PropTypes.string,
  tag: PropTypes.string,
};

type HrProperties = InferProps<
typeof hrPropertyTypes
> & {
  tag?: string;
};

const Hr = styled(({
  // eslint-disable-next-line @typescript-eslint/no-shadow
  styled,
  height,
  backgroundColor,
  margin,
  tag: Component = 'hr',
  ...properties
}: HrProperties) => <Component {...properties} />)`
  ${({
    styled: isStyled,
  }) => isStyled !== false && withStyled}
  height: ${({ height }) => height ?? '1px'};
  background-color: ${
  ({ backgroundColor }) => backgroundColor ?? 'var(--black-medium, #404040)'
};
  margin: ${({ margin }) => margin ?? '0px'};
`;

Hr.propTypes = hrPropertyTypes;

export default Hr;
