import React from 'react';
import PropTypes from 'prop-types';
import ValidationError from '@fuww/library/src/ValidationError';

const ServerValidation = ({ errors: { graphQLErrors, message } }) => (
  <>
    { graphQLErrors && graphQLErrors.map(({ message: graphQLErrorMessage }) => (
      <ValidationError key={graphQLErrorMessage}>
        {graphQLErrorMessage}
      </ValidationError>
    ))}
    {
      !graphQLErrors && message && (
        <ValidationError>
          {message}
        </ValidationError>
      )
    }
  </>
);

ServerValidation.propTypes = {
  errors: PropTypes.shape({
    graphQLErrors: PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string,
      }),
    ),
    message: PropTypes.string,
  }).isRequired,
};

export default ServerValidation;
